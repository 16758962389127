body {
  margin: 0;
  background-color: black;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  text-decoration: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: rgb(69, 68, 68);
}

::-webkit-scrollbar-thumb {
  background: gray;
  border-radius: 10px;
}

* {
  scrollbar-width: thin;
  scrollbar-color: gray rgb(54, 53, 53);

}



@font-face {
  font-family: NVRFNSHD1;
  src: url(../public/assets/content/fonts/NVRFNSHD/WOFF/nvrfnshd-displayitalic.woff);
  font-style: italic;
}

@font-face {
  font-family: NVRFNSHD3;
  src: url(../public/assets/content/fonts/NVRFNSHD/WOFF/nvrfnshd-italic.woff);
  font-style: italic;
}

@font-face {
  font-family: NVRFNSHD33;
  src: url(../public/assets/content/fonts/NVRFNSHD/WOFF/2766eaaa7a34c4a6.woff2);
  font-style: italic;
}

@font-face {
  font-family: primary_WOFF;
  src: url(../public/assets/content/fonts/NVRFNSHD/WOFF/08e12cc6918e6e88-s.p.woff2);
}

@font-face {
  font-family: primary_WOFF_;
  src: url(../public/assets/content/fonts/NVRFNSHD/WOFF/08e12cc6918e6e88-s.p.woff2);

}

@font-face {
  font-family: _primary_WOFF;
  src: url(../public/assets/content/fonts/NVRFNSHD/WOFF/0565a1b6489e2272-s.p.woff2);
  font-weight: bold;
}

@font-face {
  font-family: HOME_WOF;
  src: url(../public/assets/content/fonts/NVRFNSHD/WOFF/0565a1b6489e2272-s.p.woff2);
  font-weight: inherit;
  font-size: inherit;

}
