.works {
    display: flex;
    flex-direction: column;
    gap: 4rem;
    width: 100vw;
    height: 100%;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    padding-top: 1rem;
}

.works .carousel {
    width: 100%;
}

.works .jobs {
    display: grid;
    align-items: start;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    width: 100%;
    padding: 3rem 2rem;
    box-sizing: border-box;
}

.works .jobs .card {
    display: flex;
    flex-direction: column;
    cursor: pointer;
    overflow: hidden;
    transition: transform 0.3s ease;
}

.works .jobs .card:hover {
    transform: scale(1.02);
}

.works .jobs .card .text {
    display: flex;
    flex-direction: column;
    text-align: start;
    font-size: 1.2rem;
    color: rgb(0, 0, 0);
    padding: 10px;
    gap: 0.7rem;
    box-sizing: border-box;
    font-family: _primary_WOFF;
}

.works .jobs .card .text h3 {
    margin: 0;
    font-size: 1rem;
    font-family: _primary_WOFF;
    font-weight: bold;
    color: white;
}

.works .jobs .card .text p {
    color: rgb(0, 0, 0);
    font-size: 1.2rem;
    font-family: primary_WOFF;
    color: white;

}

.works .footer_section {
    width: 100%;
}

@media (max-width: 1200px) {
    .works .jobs {
        grid-template-columns: repeat(3, 1fr);
    }
    .works .footer {
        padding: 0.5rem 0.2rem;
    }
}

@media (max-width: 900px) {
    .works .jobs {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 600px) {
    .works .jobs {
        grid-template-columns: 1fr;
    }
}