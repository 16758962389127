.home {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}

.home .logo_home {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 6rem;
}

.icon_center {
  width: 7rem;
}



.scroll-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
}

.contents {
  width: 100%;
  display: flex;
  flex: 0 0 100dvh; 
  align-items: center;
  justify-content: center;
  position: relative;
}

.home .footer_section {
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-end; 
  background-color: rgb(0, 0, 0);
}

.home .footer_section .sub {
  justify-content: flex-end; 
  align-items: flex-end; 
  width: 100%; 
  margin-top: auto; 
  position: relative;

}

.home .footer {
  height: 100vh;
}

.logos_down {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 95%;
  position: absolute;
  bottom: 1rem;
  font-size: 0.7rem;
  color: rgb(255, 255, 255);
  z-index: 2;
  gap: 5rem;
}

.arrow-down {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  position: absolute;
  bottom: 3rem;
  left: 50%;
  transform: translateX(-50%);
}

.box {
  display: none;
}


.logos_down .logo_tipe {
  display: flex;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.logos_down .logo_tipe .contente {
  display: flex;
  align-items: center;
  text-align: start;
  flex: 1;
  max-width: 25rem;
  gap: 1rem;
  box-sizing: border-box;
  font-family: icons;
}

.logos_down .logo_tipe .contente img {
  max-width: 100%;
  height: auto;
}

.arrow-down::after,
.arrow-down::before {
  content: '';
  position: absolute;
  width: 44vw;
  height: 1px;
  background-color: rgb(255, 255, 255);
  z-index: 1;
}

.logos_down .logo_tipe .contente .logo_mm {
  width: 6rem;
  height: 1.5rem;
  object-fit: contain;

}

.logos_down .logo_tipe .contente .logo_canneslions {
  width: 7rem;
  height: 3.2rem;
  object-fit: contain;

}

.logos_down .logo_tipe .contente .effie {
  width: 6rem;
  height: 3rem;
  object-fit: contain;

}

.logos_down .logo_tipe .contente .logo_kantar {
  width: 7rem;
  height: 1.3rem;
  object-fit: contain;

}

.arrow-down::after {
  right: 5rem;
}

.arrow-down::before {
  left: 5rem;
}

.arrow-down .line {
  background-color: #ed0e69;
  position: absolute;
  cursor: pointer;
}

.arrow-down .line:nth-child(1) {
  transform: rotate(90deg);
  width: 4rem;
  bottom: 2.9rem;
  height: 4px;
}

.arrow-down .line:nth-child(2) {
  transform: rotate(40deg);
  width: 2rem;
  bottom: 1.3rem;
  left: -0.2rem;
  height: 4px;
}

.arrow-down .line:nth-child(3) {
  transform: rotate(500deg);
  width: 2rem;
  bottom: 1.3rem;
  right: -.2rem;
  height: 4px;
}

.lines-container {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  height: 5rem;
  width: 3rem;
  position: relative;
  z-index: 3;
  transition: transform 0.5s ease;
}

.lines-container:hover {
  transform: translateY(1rem);
}

@media (max-width: 1500px) {
  .logos_down .logo_tipe {
    gap: 0.6rem;
  }

  .logos_down .logo_tipe .contente {
    gap: 0.8rem;
    width: 15rem;
  }

  .logos_down .logo_tipe .contente .logo_mm {
    width: 4rem;
    height: 1.5rem;
  }

  .logos_down .logo_tipe .contente .logo_canneslions {
    width: 5rem;
    height: 2.7rem;
  }

  .logos_down .logo_tipe .contente .effie {
    width: 3.9rem;
    height: 3.1rem;
  }

  .logos_down .logo_tipe .contente .logo_kantar {
    width: 5rem;
    height: 1rem;
  }

  .kantar {
    padding-right: 1rem;
  }
}

@media (max-width: 1000px) {
  .logos_down .logo_tipe .contente {
    display: none;
  }

  .arrow-down::after,
  .arrow-down::before {
    display: none;
  }

  .arrow-down {
    bottom: 0;
  }

  .box {
    position: absolute;
    bottom: 3rem;
    display: block;
    width: 100%;
  }

  .box p {
    margin: 0;
    font-size: 1rem;
  }

  .contente_box {
    display: flex;
    gap: .8rem;
    width: 100%;
    flex-direction: column;
    align-items: center;
  }

  .lines-container {
    height: 3rem;
  }

  .lines-container:hover {
    transform: none;
  }
  
  
  .arrow-down .line {
    background-color: #ed0e69;
    position: absolute;
    cursor: pointer;
  }
  
  .arrow-down .line:nth-child(1) {
    transform: rotate(90deg);
    width: 2rem;
    bottom: 0.9rem;
    height: 4px;
  }
  
  .arrow-down .line:nth-child(2) {
    transform: rotate(40deg);
    width: 1.4rem;
    bottom: 0.2rem;
    left: 0.32rem;
    height: 4px;
  }
  
  .arrow-down .line:nth-child(3) {
    transform: rotate(500deg);
    width: 1.4rem;
    bottom: 0.2rem;
    right: 0.32rem;
    height: 4px;
  }

  .logo_mm {
    width: 100px; 
    height: 50px; 
    object-fit: contain; 
  }
  
  .logo_canneslions {
    width: 120px; 
    height: 60px; 
    object-fit: contain;
  }
  
  .effie {
    width: 100px; 
    height: 50px; 
    object-fit: contain; 
  }
  
  .logo_kantar {
    width: 120px; 
    height: 60px; 
    object-fit: contain;
  }
  
 
}

@media (max-width: 800px) {
  .logo_home .logo {
    width: 98%;
  }

  .home .logo {
    width: 100%;
  }

  .logos_down .logo_tipe .contente img {
    width: auto;
  }

  .footer_section {
    height: auto;
  }
  
  .home .footer {
    gap: 0.6rem;
  }

  .home .footer p {
    margin: 5px;
  }

  .home .footer_section .sub {
    height: 100%;
  }

  
}