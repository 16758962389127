.results {
    display: flex;
    flex-direction: column;
    justify-content: start;
    text-align: start;
    width: 70%;
    font-size: 1.125rem;
    line-height: 1.75rem;
    letter-spacing: -.025em;
    text-wrap: pretty;
    position: relative;
    height: auto;
    min-width: 24rem;
    max-width: auto;
}

.results-text .underline {
    text-decoration: underline; 
    font-weight: bold; 
}


.results.padding {
    padding: 4rem 0 0 0;
}

.results p {
    font-size: 1.25rem;
    font-family: texto;
}

.results .results-title {
    font-family: NVRFNSHD;
    font-weight: 600;
    font-size: 2.4rem;
}


@media (max-width: 1000px) {
    .results {
        width: 90%;
        height: auto;    
    }
}

@media (max-width: 750px) {
    .results {
        width: 90%;
        margin: 0;
    }

    .results p {
        font-size: 1.2rem;
        font-family: texto;
        width: 90%;
    }
}