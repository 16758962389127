.nav {
    position: fixed;
    top: 0;
    right: -100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    width: 100%; 
    height: 100%;
    z-index: -1;
    background-color: rgb(0, 0, 0);
    transition: right 1s ease-in-out; 
}

.nav.open {
    right: 0; 
}

.nav a {
    cursor: pointer;
    font-size: 6rem;
    line-height: 1;
    color: white;
    text-decoration: none;

    font-family: NVRFNSHD;
}

.nav a:hover {
    color: var(--hover-color);
    font-weight: bolder;
    line-height: 1;
    font-family: NVRFNSHD33;
    font-weight: bold;
}


@media (max-width: 800px) {
    .nav a {
        font-size: 3rem;
    }

    .nav .head {
        position: absolute;
        top: 1rem;
        left: 1rem;
    }
}