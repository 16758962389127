.header {
    position: fixed;
    display: flex;
    justify-content: space-between;
    text-align: center;
    width: 100%;
    top: 0.3rem;
    z-index: 9;
}

.header img {
    width: 6rem;
    position: relative;
}

.menu {
    display: flex;
    flex-direction: column;
    position: relative;
    cursor: pointer;
    height: 100%;
    top: 1rem;
    right: 1rem;
    right: -0.4rem;
    flex: 0 80px;
}

.menu .line {
    display: block;
    width: 27px;
    height: 3px;
    border-radius: 5px;
    background: rgb(255, 255, 255);
    margin: 2.3px auto;
    transition: background-color 0.3s, transform 0.3s, margin-left 0.3s;
    cursor: pointer;
}

.menu:hover .line {
    background-color: rgb(175, 248, 132);

}

.menu .line:nth-child(2) {
    margin-left: 1.7rem;
    width: 16px;
    height: 3px;
}

.menu:hover .line:nth-child(2) {
    margin-left: 2.3rem;
}

.menu.open .line:nth-child(1) {
    transform: rotate(50deg);
    margin-top: .5rem;

    width: 1.8rem;
}

.menu.open .line:nth-child(2) {
    opacity: 0;
}


.menu.open .line:nth-child(3) {
    transform: rotate(-50deg);
    margin-top: -13px;
    
}


@media (max-width: 800px) {

    .nav a {
        font-size: 3rem;
    }

    .header .gif {
        display: none;
    }

    .header img {
        right: -1rem;
    }

    .nav .head {
        position: absolute;

    }

    .menu {
        margin-left: auto; 
    }
}