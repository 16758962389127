.info {
    display: flex;
    flex-direction: column; 
    justify-content: center;
    align-items: center;
    width: 70%;
    max-height: auto;
    min-height: 100vh; 
}

.info p {
    font-size: 2.45rem;
    text-align: start;
    line-height: 2.5rem;
    font-family: NVRFNSHD;
    margin-bottom: 1.5rem;
}

@media (max-width: 1000px) {
    .info {
        width: 97%;
        padding: 0;
        min-height: 100%;
    }

    .info p {
        font-size: 1.5rem;
        line-height: 1.6rem;
        text-align: center;
    }
}

@media (max-width: 800px) {
    .info {
        width: 100%;
    }

    .info p {
        font-size: 1.5rem;
        line-height: 2rem;
        text-align: center;
    }
}

@media (max-width: 500px) {
    .info {
        width: 100%;
        padding: 1rem 0;
    }

    .info p {
        font-size: 1rem;
        line-height: 1.5rem;
    }
}
