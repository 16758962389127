.text p {
    display: flex;
    font-size: 3.75rem;
    color: white;
    text-align: start;
    line-height: 0.9;
    /* font-family: HOME_WOF; */
    font-family: texto;
    letter-spacing: -.05em;
    font-weight: inherit;
    margin: 0
}


@media (max-width: 1300px) {
    .text p {
        font-size: 3.75rem;

    }

}

@media (max-width: 800px) {
    .text p {
        font-size: 3rem;
        line-height: 1;
        letter-spacing: -.05em;

    }

}

@media (max-width: 500px) {
    .text p {
        font-size: 2.7rem;

    }

}