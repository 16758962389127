.logo_content {
    z-index: 1;
    height: 1.5rem;
    margin-top: 1.1rem;
}

.logo_conte img {
    width: 5rem;
    height: 100%;
    object-fit: cover;
    mix-blend-mode: multiply;
    opacity: 1;
}