.card {
  display: flex;
  justify-content: center;


}

.card-background {
  top: 0;
  left: 0;
  width: 100%;
  max-height: 100%;
  object-fit: cover; 
}

.card-content {
  height: 100%; 
  display: flex;
  justify-content: center; 
  align-items: center; 
}

.card-content.center {
  align-items: center;
  justify-content: center;

}

.card-content.start {
  align-items: flex-start;
  justify-content: flex-start;
}

.card-content.end {
  align-items: flex-end;
  justify-content: flex-end;

}

.down {
  display: flex;
  height: auto;
  gap: 2rem;
  width: 98%;
  align-items: flex-end;
  z-index: 7;
  position: absolute;
  bottom: 0;
  padding: 1rem;
  justify-content: space-between;

}

.btn_down {
  display: inline-flex;
  align-items: center;
  padding: 0.1rem .1rem;
  border-radius: 0.25rem;
  white-space: nowrap;
}

@media (max-width: 800px) {

  .down {
    flex-direction: column;
    gap: 1rem;
    align-items: start;
    margin: 1rem;
  }

  .btn_down {
    padding: 0;
    white-space: inherit;
    text-align: start;
  }
}
