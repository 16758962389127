.img {
    width: 98.5%;
    height: 100vh; 
    display: flex;
    align-items: center; 
    justify-content: center;
    padding: .4rem 0;
}

.img img {
    width: 100%;
    height: 100%;
    object-fit: cover; 
}


@media (max-width: 800px) {
    .img {
        padding: .2rem 0;
    }
    
}