.btn {
    color: white;
    display: flex;
    letter-spacing: -.025em;
    gap: .1rem;
    font-size: 2.25rem;
    align-items: center;
    justify-content: center;
    border: 2px solid white;
    border-radius: 2rem;
    padding: .7rem 1rem;
    cursor: pointer;
    transition: all 0.5s ease;
    font-family: primary_WOFF;
    font-weight: bold;
}

.btn:hover {
    background-color: white;
    color: black;
}


@media (max-width: 1500px) {
    .btn {
        font-size: 1.2rem;
        letter-spacing: -.025em;
    }

}