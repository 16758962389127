.footer {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: center;
    gap: 3rem;
    color: white;
    padding-top: .5rem;
    bottom: 0;
    z-index: 0;
    transition: background-color 0.3s ease, color 0.3s ease;
    position: relative;
    height: 100%;
}

.footer img {
    width: 96%;
}

.footer .content {
    display: flex;
    text-align: start;
    justify-content: space-between;
    width: 96%;
}

.footer .content p {
    flex-direction: column;
    margin-bottom: 20px;
    font-size: 1.125rem;
    line-height: 1.5rem;
    letter-spacing: -.025em;
    text-wrap: pretty;
}

.footer .contact {
    align-items: start;
}

.obs span {
    cursor: pointer;
}

.contact,
.family {
    font-family: primary_WOFF_;
    font-weight: 600;
}

.contact span,
.family span {
    font-family: _primary_WOFF;
    font-weight: bold;
}

.footer .family {
    display: flex;
    gap: 10rem;
    width: 100%;
}

.footer .sub_footer {
    display: flex;
    justify-content: space-between;
    width: 96%;
    position: relative;
    font-family: primary_WOFF_;
    font-weight: 600;
    text-align: start;
}

.footer a {
    text-decoration: none;
    color: inherit;
}

.footer a:hover {
    border-bottom: 1px solid currentColor;
}

.sub_footer.default-line::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: black;
    top: -1rem;
    text-align: start;
    transition: background-color 0.3s ease;
}

.sub_footer.white-line::after {
    content: '';
    position: absolute;
    height: 1px;
    width: 100%;
    background-color: white;
    top: -1rem;
    text-align: start;
    transition: background-color 0.3s ease;
}

.sub_footer .face img {
    width: 8rem;
    cursor: pointer;
}

.footer.black-text a {
    color: black;
}

.footer.white-text a {
    color: white;
}

/* POLITICAS */

.policy-modal {
    position: absolute;
    width: auto;
    height: auto;
    bottom: 0;
    left: 0;
    background-color: rgb(0, 0, 0);
    transition: transform 0.3s ease;
    transform: translateY(120%);
    z-index: 9;
    color: rgb(255, 255, 255);
    border-radius: 1rem 1rem 0 0;
    font-family: primary_WOFF_;
    white-space: nowrap;
    border: 1px solid currentColor;
    margin: 0 0 1rem 1rem;
}

.policy-modal.show {
    transform: translateY(0);
}

.policy-modal .policy-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100%;
    justify-content: flex-start;
    margin: 0 2rem;
    align-items: start;
}

.policy-modal a {
    position: relative;
    text-decoration: none;
    color: inherit;
    border-bottom: 0.8px solid transparent;
    transition: border-color 0.3s ease;
}

.policy-modal a:hover {
    border-bottom: 0.8px solid currentColor;
}

.policy-modal button {
    position: absolute;
    background-color: transparent;
    align-items: center;
    top: 0.5rem;
    right: 0.5rem;
    border: none;
    cursor: pointer;
}

.policy-modal button ion-icon {
    font-size: 2rem;
    cursor: pointer;
    color: white;
    transition: transform .2s;
}

.policy-modal button ion-icon:hover {
    transform: scale(1.1);
}


.policy-modal h2 {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    color: white;
    margin: 0;
    border-radius: 1rem 1rem 0 0;
    background: linear-gradient(90deg, rgba(58, 167, 222, 1) 0%, rgba(42, 93, 172, 1) 27%, rgba(165, 58, 103, 1) 53%, rgba(219, 40, 111, 1) 75%, rgba(249, 199, 10, 1) 100%);
}

.policy-modal .policy-content {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding: 2rem 0;
}


@media (max-width: 1000px) {
    .footer img {
        width: 97%;
    }

    .footer .content {
        display: flex;
        gap: 2rem;
        flex-direction: column;
    }

    .footer .family {
        display: flex;
        gap: 3rem;
        justify-content: left;
    }

    .footer .sub_footer {
        width: 97%;
    }

    .footer .contact {
        align-items: start;
        line-height: 0.0;
    }

    .contact,
    .family {
        flex: 1;
        margin: 0 0rem;
    }
}

@media (max-width: 740px) {
    .footer .content {
        gap: 2rem;
        width: 97%;
        font-size: .9rem;
        margin-top: 1rem;
    }

    .footer .content .find {
        line-height: 1;
    }

    .sub_footer {
        display: flex;
        flex-direction: column;
        align-items: start;
    }

    .policy-modal {
        width: 90%;
    }

    .policy-modal a {
        font-size: .7rem;
    }

    .footer .content a,
    .footer .sub_footer {
        text-decoration: none;
        color: inherit;
        font-size: 0.9rem;
    }
    
}