@keyframes color-change {
    0% {
        background-color: #1db101;
    }

    20% {
        background-color: #1b5cff;
    }

    40% {
        background-color: #7d2e61;
    }

    60% {
        background-color: #ed0e69;
    }

    80% {
        background-color: #ed3d25;
    }

    100% {
        background-color: #ffd601;
    }
}

.tape {
    display: flex;
    align-items: center;
    height: 5rem;
    overflow: hidden;
    position: relative;
    /* animation: color-change 10s linear infinite; */
    background: linear-gradient(90deg, rgba(58, 167, 222, 1) 0%, rgba(42, 93, 172, 1) 27%, rgba(165, 58, 103, 1) 53%, rgba(219, 40, 111, 1) 75%, rgba(249, 199, 10, 1) 100%);
}

.tape .scrolling-content {
    display: flex;
    gap: 3rem;
    align-items: center;
    white-space: nowrap;
    position: absolute;
    animation: scroll-left 90s linear infinite;
    will-change: transform;
    width: 200%;
}


.tape .scrolling-content span {
    font-family: primary_WOFF_;
    font-weight: 100;
}

.tape .scrolling-content .btn {
    border: 2px solid white;
    padding: 0.2rem 1rem;
    border-radius: 5rem;
    color: rgb(255, 255, 255);
    position: relative;
    transition: all 1s ease;

}

.tape .scrolling-content .btn:hover {
    color: rgb(0, 0, 0);
}

.tape .scrolling-content .btn::after {
    content: '';
    position: absolute;
    right: -2rem;
    width: 10px;
    height: 4rem;
    background-color: rgb(255, 255, 255);
    transform: rotate(30deg);
    clip-path: polygon(0% 30%,
            100% 20%,
            100% 70%,
            0% 80%);
}

@keyframes scroll-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translateX(-100%);
    }
}

.paused .scrolling-content {
    animation-play-state: paused;
}


@media (max-width: 1400px) {
    .tape .scrolling-content span {
        font-family: primary_WOFF_;
        font-weight: 100;
        font-size: 1.2rem;
    }

    .tape .scrolling-content .btn {
        padding: 0.5rem 1.5rem;

    }
}