.festivais {
    display: flex;
    flex-direction: column;
    text-align: start;
    box-sizing: border-box;
    height: auto;
    width: auto;
    padding: 6rem 0 4rem 0;
}

.festivais .content {
    display: flex;
    flex-direction: column;
    justify-content: center;

    gap: 2rem;
}

.festivais.more-than-four .content {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(2, 1fr);
}


.festivais .itens {
    display: flex;
    position: relative;
    gap: 1.7rem;
}

.festivais p {
    white-space: unset;
    font-size: 1.2rem;
    line-height: 1.2;
    font-family: texto;
    margin: 0;
    white-space: nowrap;
}

.title_fest {
    display: flex;
    align-items: end;
    width: 3.7rem;
    height: 1.5rem;
    padding-left: 0.3rem;
    margin-bottom: 1rem;
    margin-top: 2rem;
}

.title_fest img {
    width: 3.8rem;
    height: 3.3rem;
    margin-right: 0.8rem;
    object-fit: contain;
}


.festivais .awards {
    display: flex;
}

.festivais .contente {
    display: flex;
    flex-direction: column;
    gap: 0.2rem;
}

.festivais .name {
    font-family: NVRFNSHD;
    font-weight: 700;
    font-size: 2.2rem;
}

@media (max-width: 1000px) {
    .festivais {
        padding-left: 2rem;
        width: 100%;
    }
}

@media (max-width: 800px) {
    .festivais .content {
        grid-template-columns: 1fr;
        gap: 1rem;
        justify-content: center;
    }

    .title_fest img {
        width: 3.2rem;
        height: 3rem;
        margin-right: 0.8rem;
    }

    .festivais .name {
        font-size: 1.2rem;
    }

    .festivais p {
        font-size: 0.8rem;
    }

    .festivais {
        height: auto;
    }

    .festivais .itens {
        display: flex;
        position: relative;
        gap: 0.6rem;
    }
}

@media (max-width: 700px) {
    .festivais.more-than-four .content {
        grid-template-columns: 1fr;
    }

    .festivais {
        display: flex;
        flex-direction: column;
        text-align: start;
        padding: 1rem;
        box-sizing: border-box;
        height: auto;
        padding: 0;
        gap: 3rem;
    }

    .festivais p {
        white-space: inherit;
    }
}