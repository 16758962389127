.contact {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 0;
    margin: 0;
}

.contact .footer_section {
    display: flex; 
    flex-direction: column; 
    width: 100%;
    height: 100vh;
}

iframe {
    height: 100dvh;
    flex-grow: 1;
    width: 100%;
}


@media (max-width: 1300px) {
    .contact .footer {
        padding: 0;
    }
    
    .contact .footer_section .footer .content {
        font-size: 1.6rem;
    }
    
    .contact .footer_section .footer .content h4 {
        font-family: _primary_WOFF;
    }
    
    .contact .footer_section .footer .find {
        line-height: 1;
    }
    
    .contact .footer_section .contact,
    .family {
        margin: 0 0rem;
        font-family: primary_WOFF;
    }
}

@media (max-width: 1000px) {
   .contact_footer  {
    height: 100vh;
   }
}