.info3 {
    display: flex;
    text-align: start;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    min-height: 100vh; 
    font-family: NVRFNSHD;

    color: rgb(0, 0, 0);
}

.info3 .contente {
    width: 70%;
}

.info3 .citacao {
    font-size: 2.9rem;
}

.info3 .assinatura {
    font-size: 1.4rem;
}


@media (max-width: 1600px) {

    .info3 .contente {
        width: 80%;
    }

    .info3 .citacao {
        font-size: 2.7rem;
    }
    
    .info3 .assinatura {
        font-size: 1.3rem;
    }
    
}

@media (max-width: 1300px) {
    .info3 .contente {
        width: 90%;
    }

    .info3 .citacao {
        font-size: 2.2rem;
    }
    
    .info3 .assinatura {
        font-size: 1rem;
    }
}

@media (max-width: 950px) {

    .info3 {
        line-height: 1;
    min-height: 50vh; 

    }

    .info3 .citacao {
        font-size: 2rem;
    }
    
    .info3 .assinatura {
        font-size: 1rem;
    }
}


@media (max-width: 500px) {

    .info3 {
        line-height: 1;

    }

    .info3 .citacao {
        font-size: 1rem;
        margin: 0;
    }
    
    .info3 .assinatura {
        font-size: 0.8rem;
    }
}