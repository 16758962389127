.info2 {
    width: 90%;
    max-height: auto;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    gap: 3rem;
    align-items: start;
    justify-content: center;
    padding: 5rem 0;
}

.info2 p {
    font-size: 1.7rem;
    text-align: start;
    font-family: texto2;
    margin: 0 0 0 50%;
    line-height: 1.7rem;
    align-items: start;
    justify-content: start
}

@media (max-width: 1300px) {
    .info2 p {
        font-size: 1.3rem;
    }
}


@media (max-width: 1000px) {

    .info2 {
        text-align: center;
        width: 97%;
        min-height: 100%;
        padding: 1rem 0;
        gap: 0.1rem;

    }


    .info2 p {
        font-size: 1.7rem;
        line-height: 1.6rem;
        text-align: center;
        margin: 1rem;

    }
}

@media (max-width: 500px) {

    .info2 {
        text-align: center;
        width: 100%;
    }

    .info2 p {
        font-size: 1.2rem;
        line-height: 1rem;
    }
}