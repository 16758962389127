.video-container {
    position: relative;
    height: 100%;
    width: 98.5%;
    padding: .4rem 0;

}

.video-container.full-width {
    width: 100%;
    height: 100%;
}

.video-container .video-poster {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    cursor: pointer;
    z-index: 1;
    align-items: center;

}

.video-container .poster-image {
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: repeat;
    object-fit: cover;
    position: absolute;
}

.video-container video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    overflow: hidden;
}

.play-overlay {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    font-size: 3rem;
}

.play-icon {
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 2rem;
    background-color: rgba(128, 128, 128, 0.415);
    width: 2.8rem;
    height: 3rem;
    padding: 0 0 0 5px;
    border-radius: 50%;
}

@media (max-width: 800px) {
    .video-container {
        position: relative;
        height: 100%;
        padding: .02rem .3rem;
    }

    .play-button {
        display: flex;
    }
}