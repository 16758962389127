.case {
    width: 100%;
    height: 100%;
    background-color: white;
}

.content_case {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.padding-horizontal {
    padding-left: 16px; 
    padding-right: 16px; 
    background-color: lightgray;
}

.content_case {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.case .text_head {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background-color: rgb(16, 16, 16);
    height: 30rem;
    width: 100%;
    color: white;
    margin-bottom: -1rem;
}

.case .text_head .title {
    width: 70%;
    text-align: start;
    line-height: 3.7rem;
    letter-spacing: -0.1rem;
}

.case .text_head .title .title_prim {
    font-size: 3.75rem;
    line-height: 1;
    letter-spacing: -.05em;
    font-family: primary_WOFF;
}

.case .text_head .title p {
    font-size: 2.25rem;
    align-items: center;
    display: flex;
    gap: 1rem;
    line-height: 2rem;
    font-family: primary_WOFF_;
    font-weight: 700;
}

.case .text_head .title p span {
    display: flex;
    font-size: 2.25rem;
    align-items: center;
    border: 2px solid #080808;
    border-radius: 5rem;
    letter-spacing: -.025em;
    padding: 0.9rem 1.2rem;
    animation: borderColorChange 5s infinite;
}

.results-festivais-container {
    display: flex;
    justify-content: center;
    flex-direction: row;
    width: 70%;
    height: 100vh;
    gap: 3rem;
    align-items: center;
    position: relative;
}

.results-content {
    display: flex;
    gap: 4rem;
    justify-content: start;

}

.footer_down .fot {
    height: auto;
}

@keyframes borderColorChange {
    0% {
        border-color: #1db101;
    }

    16% {
        border-color: #1b5cff;
    }

    32% {
        border-color: #7d2e61;
    }

    48% {
        border-color: #ed0e69;
    }

    64% {
        border-color: #ed3d25;
    }

    80% {
        border-color: #ffd601;
    }

    100% {
        border-color: #1db101;
    }
}

@media (max-width: 1500px) {
    .case .text_head .title {
        width: 80%;
    }

    .case .text_head .title .title_prim {
        margin: 0rem 0;
    }

    .case .text_head .title p,
    .case .text_head .title p span {
        font-size: 1.125rem;
    }
}

@media (max-width: 1000px) {
    .results-festivais-container {
        display: flex;
        flex-direction: column;
        width: 95%;
        height: auto;
        padding: 0;
    }

    .results-content {
        display: flex;
        flex-direction: column;
        width: 95%;
        height: auto;
        padding: 0;
    }
    
}

@media (max-width: 750px) {
    .case .text_head .title {
        width: 96%;
    }

    .case .text_head .title .title_prim {
        font-size: 2.7rem;
        line-height: 1;
        letter-spacing: -.05em;
    }

    .case .text_head .title p {
        font-size: 1.5rem;
    }

    .case .text_head .title p span {
        display: flex;
        align-items: center;
        border: 2px solid #c3f546;
        border-radius: 5rem;
        cursor: pointer;
        transition: all 0.5s ease;
        padding: 0.5rem 1.2rem;
    }

    .case .info {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
    }

    .case .info p {
        width: 90%;
        font-size: 1.7rem;
    }
}

@media (max-width: 750px) {
    .results {
         min-width: 0rem;
         margin: 2rem 0;
    }
    .case .text_head .title p span {
        white-space: nowrap;
    }

    .results-festivais-container {
        display: flex;
        flex-direction: column;
        width: 95%;
        padding: 0;
    }

    .results-content {
        gap: 2rem;
        padding: 0 0 4rem 0;
    }
}