.gallery {
    width: 98.5%;
    display: flex;
    padding: .5rem 0;
    gap: 1rem;
    flex-wrap: wrap; 
    
}

.gallery img,
.gallery video {
    flex: 1 1 auto;
    max-width: 100%; 
    height: auto; 
    object-fit: cover;
}

@media (max-width: 500px) {
    .gallery {
        padding: .2rem 0;
        gap: 0.4rem;
    }
}
