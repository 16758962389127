.App {
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: transparent;
  overflow: hidden;
  overflow-x: hidden;
}

@font-face {
  font-family: texto2;
  src: url(../public/assets/content/fnts/CodeNext-Book.otf);
}

@font-face {
  font-family: texto;
  src: url(../public/assets/content/fnts/CodeNext-Book.otf);
}

@font-face {
  font-family: icons;
  src: url(../public/assets/content/fnts/CodeNext-Book.otf);
}

@font-face {
  font-family: NVRFNSHD;
  src: url(../public/assets/content/fonts/NVRFNSHD/WOFF/nvrfnshd-display.woff);
  font-style: normal;
}